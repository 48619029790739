import React, { useState } from 'react'
import { parseFloatOrUndefined, parseIntOrUndefined } from '../../../../utils/utils';

export const useGoodsAndServicesForm = () => {

    // State
    const [id, setId] = useState('');
    const [provisionRowNumber, setProvisionRowNumber] = useState();
    const [description, setDescription] = useState();
    const [quantity, setQuantity] = useState();
    const [units, setUnits] = useState();
    const [unitPrice, setUnitPrice] = useState();
    const [totalAmount, setTotalAmount] = useState(0);
    const [observations, setObservations] = useState();

    //validations

    const addValidation = () => {
        if (provisionRowNumber && description && quantity && units && unitPrice) {
            return true;
        } else return false;
    };

    const withValueLimitImports = (inputObj) => {
        const { value } = inputObj;
        const maxValue = 9999999999.999999; //máximo de 10 enteros
        const minValue = -9999999999.999999;
        if (value <= maxValue && value >= minValue || value == '-') return inputObj;
    };

    const withValueLimitQuantity = (inputObj) => {
        const { value } = inputObj;
        const maxValue = 9999999999.999999; //máximo de 10 enteros
        const minValue = -9999999999.999999;
        if (value <= maxValue && value >= minValue || value == '-') return inputObj;
    };

    const defaultParams = {
        page: 0,
        size: 10
    };

    const measurementUnitList = [
        {
            id: 1,
            name: "Litros"
        },
        {
            id: 2,
            name: "Unidades"
        },
        {
            id: 3,
            name: "Kilogramos"
        },
        {
            id: 4,
            name: "Metros"
        },
        {
            id: 7,
            name: "Packs"
        },
        {
            id: 8,
            name: "Cajas"
        },
        {
            id: 5,
            name: "Meses"
        },
        {
            id: 6,
            name: "Dias"
        }
    ];

    const stopPropagate = (callback) => {
        return (e) => {
            e.stopPropagation();
            callback(e);
        };
    };

    //Handle change
    const handleChangeId = (value) => {
        setId(value);
    }
    const handleChangeLine = (value) => {
        const intValue = parseIntOrUndefined(value || 0);
        const valueLimit = 9999;
        if (intValue <= valueLimit) setProvisionRowNumber(parseIntOrUndefined(value));
    };

    const handleChangeDescription = (value) => {
        setDescription(value);
    };

    const handleChangeAmount = (imputObj) => {
        setQuantity(imputObj?.floatValue);
        handleChangeTotalPrice(unitPrice, imputObj?.floatValue);
    };

    const handleChangeUnits = (value) => {
        setUnits(value);
    };

    const handleChangeUnitPrice = (imputObj) => {
        const formattedValue = imputObj?.floatValue;
        setUnitPrice(formattedValue);
        handleChangeTotalPrice(formattedValue, quantity);
    };  

    const handleChangeTotalPrice = (price, amountParam) => {
        const price_ = parseFloatOrUndefined(price || 0);
        const amount_ = parseFloatOrUndefined(amountParam || 0);
        setTotalAmount(parseFloatOrUndefined(price_ * amount_));
    };

    const handleChangeObservation = (value) => {
        setObservations(value);
    };

    const clearForms = () => {
        setProvisionRowNumber('');
        setDescription('');
        setQuantity('');
        setUnits('');
        setUnitPrice('');
        setTotalAmount(0);
        setObservations('');
    };

    return {
        defaultParams,
        stopPropagate,
        id,
        measurementUnitList,
        provisionRowNumber,
        description,
        quantity,
        units,
        unitPrice,
        totalAmount,
        observations,
        handleChangeId,
        handleChangeLine,
        handleChangeDescription,
        handleChangeAmount,
        handleChangeUnits,
        handleChangeUnitPrice,
        handleChangeTotalPrice,
        handleChangeObservation,
        addValidation,
        clearForms,
        withValueLimitImports,
        withValueLimitQuantity
    };
};
