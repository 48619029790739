import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert, Form, Spinner } from 'react-bootstrap';
import { faPlus, faExclamationTriangle, faFilter, faBroom, faEdit, faTrash, faSearch, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { newProvisionOrder, provisionOrder } from 'src/utils/label';
import { getClearObject } from 'src/utils/utils';
import ActionIcon from 'src/components/general/ActionIcon';
import { useForm } from 'react-hook-form';
import { isNotEmptyArray } from 'src/services/validationService';
import AppLoading from 'src/components/common/AppLoading';
import RCPagination from 'src/components/common/RCPagination';
import { clearProvisionOrderListData, setProvisionOrderDetail, setProvisionOrderToEdit } from 'src/redux/provisionOrder/provisionOrderActions'
import { getAcquisitionTypesListData, getAcquisitionTypesListIsFetching, getProvisionOrderListData, getProvisionOrderListIsFetching } from 'src/redux/provisionOrder/provisionOrderReducer';
import { tryAcquisitionTypeList, tryDeleteProvisionOrder, tryGetProvisionOrderDetails, tryProvisionOrderById } from 'src/redux/provisionOrder/provisionOrderActionCreator';
import { PROVISION_ORDER_NEW, PROVISION_ORDER_EDIT, PROVISION_ORDER_DETAILS } from 'src/utils/constants';
import { tryGetReportProvisionOrderById } from 'src/redux/reports/reportsActionCreactor';
import { getReportProvisionOrderIsFetching } from 'src/redux/reports/reportsReducer';
import { AdministrativeServiceData } from '../../../utils/administrativeServiceUtils';
import { getListIdsServiceAdministrativeByUser } from '../../../redux/administrativeService/administrativeServiceReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

const ProvisionOrderListPage = () => {
	const dispatch = useDispatch();
	const [filterObject, setFilterObject] = useState({});
	const { handleSubmit, register, reset } = useForm();
	const figurativeExpedituresRefContainer = useRef(null);
	const colSpanCellsNumber = figurativeExpedituresRefContainer?.current?.cells.length;
    const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

	// Fetch Wage Template
	const provisionOrderListData = useSelector(state => getProvisionOrderListData(state));
	const isFetching = useSelector(state => getProvisionOrderListIsFetching(state));
	const records = provisionOrderListData?.records;
	const hasRecords = isNotEmptyArray(records);
	const provisionOrderReportisFetching = useSelector(state => getReportProvisionOrderIsFetching(state));
	const acquisitionTypesListData = useSelector(state => getAcquisitionTypesListData(state));
	const acquisitionTypesListIsFetching = useSelector(state => getAcquisitionTypesListIsFetching(state));

	// Pagination
	const page = provisionOrderListData?.metadata?.page + 1;
	const totalItems = provisionOrderListData?.metadata?.filteredCount;
	const perPage = provisionOrderListData?.metadata?.pageSize;

    const listIdsServiceAdministrative = useSelector(state => getListIdsServiceAdministrativeByUser(state));
    const administrativeServiceData = AdministrativeServiceData();
    const [serviceId, setServiceId] = useState(listIdsServiceAdministrative);
	const handleAdministrativeService = value => {
        const serviceValue = value != '' ? value : defaultParams?.administrativeServiceId
        setServiceId(serviceValue)
        const newFilterObject = {
            ...defaultParams,
            ...filterObject,
            // year: selectedPeriod?.year,
            administrativeServiceId: serviceValue,
        };
        fetchList(newFilterObject);
    };

	const defaultParams = {
		filter: {
			page: 0,
			size: 10,
			sort: 'number,desc',
		},
		administrativeServiceId: listIdsServiceAdministrative,
		year: selectedPeriod?.year,
	};

	const loadAllData = () => {
		dispatch(clearProvisionOrderListData());
		dispatch(tryGetProvisionOrderDetails(defaultParams));
	};

	const onPageChange = (pNumber) => {
		const object = {
			...defaultParams,
			...filterObject,
			page: pNumber - 1
		};
		fetchList(object);
	};

	const onClickNewProvisionOrder = () => {
		dispatch(push(PROVISION_ORDER_NEW));
	};

	const onClickDetail = provisionOrder => {
		dispatch(setProvisionOrderDetail(provisionOrder));
		dispatch(push(PROVISION_ORDER_DETAILS));
	};

	const handleDownload = (id) => {
		dispatch(tryGetReportProvisionOrderById(id));
	};

	const onClickEdit = provisionOrder => {
		dispatch(tryProvisionOrderById(provisionOrder?.id)).then(
			(response) => {
				dispatch(setProvisionOrderToEdit(response));
				dispatch(push(PROVISION_ORDER_EDIT));
			}
		);
	};

	const onClickDelete = idProvisionOrder => {
		swal({
			title: 'Confirmación',
			text: '¿Realmente desea eliminar la orden de provisión?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(tryDeleteProvisionOrder(idProvisionOrder?.id)).then(
					response => {
						if (response?.status == 204) {
							dispatch(clearProvisionOrderListData());
							loadAllData();
						}
					}
				);
			}
		});
	};

	const onSubmitForm = (data) => {
		if (!isFetching) {
			const filter = {
				//administrativeServiceId: servicio,
				'ad.codeOrganism': data?.codeOrganism,
				'ad.number': data?.expedientNumber,
				'ad.year': data?.expedientYear,
				number: data?.number,
				initiator: data?.initiator,
				legalInstrumentType: data?.legalInstrumentType,
				legalInstrumentNumber: data?.legalInstrumentNumber,
				legalInstrumentYear: data?.legalInstrumentYear,
				acquisitionType: data?.acquisitionType,
				beneficiaryName: data?.beneficiaryName,
                administrativeServiceId: serviceId,
				...defaultParams.filter
			};
			const filterToSend = getClearObject(filter);
			setFilterObject(filterToSend);
			dispatch(clearProvisionOrderListData());
			dispatch(tryGetProvisionOrderDetails(filterToSend));
		}
	};

	const fetchList = params => {
		dispatch(tryGetProvisionOrderDetails(params));
	};

	const cleanFilters = () => {
		setFilterObject({})
		reset();
        setServiceId(listIdsServiceAdministrative);
		fetchList(defaultParams);
	};

	useEffect(() => {
		loadAllData();
		cleanFilters();
		dispatch(tryAcquisitionTypeList());
	}, []);

	return <>
		<Container fluid className='lisrtin'>
			<Card className='mb-5'>
				<Card.Header className='h6'>
					{provisionOrder}
				</Card.Header>
				<Card.Body>
					<div className='d-flex'>
						<Button size='md' className='mb-3 btn-success' onClick={onClickNewProvisionOrder}>
							<FontAwesomeIcon icon={faPlus} className='mr-1' />
							{newProvisionOrder}
						</Button>
						{administrativeServiceData?.length > 1 &&
							<select
								value={serviceId}
								className='form-control col-3 mx-3'
								onChange={(e) => handleAdministrativeService(e.target.value)}
							>
								<option value={''}>Todos los servicios</option>
								{Array.isArray(administrativeServiceData) && 
									administrativeServiceData.map(item => (
										<option className='py-2' value={item.id} key={item.id}>
											{item.code} - {item.shortName}
										</option>
									))
								}
							</select>
						}
					</div>
					<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off' >
						<Table striped hover size='sm'>
							<thead>
								<tr ref={figurativeExpedituresRefContainer}>
									<th className='text-center align-middle' width='12%'>Número expediente</th>
									<th className='text-center align-middle' width='12%'>Número orden</th>
									<th className='text-center align-middle' width='15%'>Iniciador</th>
									<th className='text-center align-middle' width='15%'>Proveedor</th>
									<th className='text-center align-middle' width='15%'>Instrumento legal</th>
									<th className='text-center align-middle' width='15%'>Contratación</th>
									<th className='text-center align-middle' width='16%'>Acciones</th>
								</tr>
								<tr className='secondary'>
									<th>
										<div className="input-group">
											<Form.Control name="codeOrganism" className='text-center' placeholder="Organismo" ref={register} />
											<Form.Control name="expedientNumber" className='text-center' placeholder="N°" ref={register} />
											<Form.Control name="expedientYear" className='text-center' placeholder="Año" ref={register} />
										</div>
									</th>
									<th>
										<Form.Control className='text-center' type='string' name='number' ref={register} />
									</th>
									<th>
										<Form.Control className='text-center' type='string' name='initiator' ref={register} />
									</th>
									<th>
										<Form.Control className='text-center' type='string' name='beneficiaryName' ref={register} />
									</th>
									<th>
										<div className="input-group">
											<Form.Control className='text-center' type='string' name='legalInstrumentType' placeholder="Tipo" ref={register} />
											<Form.Control className='text-center' type='string' name='legalInstrumentNumber' placeholder="N°" ref={register} />
											<Form.Control className='text-center' type='string' name='legalInstrumentYear' placeholder="Año" ref={register} />
										</div>
									</th>
									<th>
										<Form.Control
											as="select"
											name="acquisitionType"
											size='md'
											ref={register}
											className={"text-black-color"}
											disabled={acquisitionTypesListIsFetching}
										>
											<option value={''} selected>Seleccione una opción...</option>
											{
												acquisitionTypesListData?.map((item, index) => (
													<option className='text-black-color' value={item} key={index}>
														{item?.replace(/_/g, " ")}
													</option>
												))
											}
										</Form.Control>
									</th>
									<th className='text-center align-middle'>
										<div className='d-flex justify-content-around'>
											<ActionIcon
												size="lg"
												id="search-button"
												className="btn-primary search-button text-white-color"
												toolTipText="Filtrar"
												icon={faFilter}
												type='submit'
												onSubmit={onSubmitForm}
											/>
											<ActionIcon
												size="lg"
												id="clean-filter"
												className="btn-primary clean-filter text-white-color"
												toolTipText="Limpiar filtros"
												icon={faBroom}
												type='reset'
												onClick={() => cleanFilters()}
											/>
										</div>
									</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{
									hasRecords ?
										records?.map(item => {
											return (
												<tr key={item?.id}>
													<td className='text-center align-middle truncate maxTruncate' >
														{item?.administrativeDocument?.codeOrganism}-{item?.administrativeDocument?.number}/{item?.administrativeDocument?.year}
													</td>
													<td className='text-center align-middle truncate maxTruncate' >
														{item?.number}/{item?.year}
													</td>
													<td className='text-center align-middle truncate maxTruncate' >
														{item?.initiator}
													</td>
													<td className='text-center align-middle truncate maxTruncate' title={item?.beneficiary?.persona?.companyName}>
														{item?.beneficiary?.persona?.companyName}
													</td>
													<td className='text-center align-middle truncate maxTruncate' >
														{item?.legalInstrument?.legalInstrumentType?.name} {item?.legalInstrument?.number}/{item?.legalInstrument?.year}
													</td>
													<td className='text-center align-middle truncate maxTruncate' title={item?.acquisitionType?.replace(/_/g, ' ')} >
														{item?.acquisitionType?.replace(/_/g, ' ')}
													</td>
													<td className='text-center align-middle p-0'>
														<ActionIcon
															size='lg'
															className={'mx-2'}
															toolTipText='Detalle de Orden de provisiones'
															icon={faSearch}
															onClick={() => onClickDetail(item)}
														/>
														<ActionIcon
															size='lg'
															className={'mx-2'}
															toolTipText='Descargar Orden de provisiones'
															icon={faDownload}
															onClick={() => handleDownload(item?.id)}
														/>
														<ActionIcon
															size='lg'
															className={'mx-2'}
															toolTipText='Editar Orden de provisiones'
															icon={faEdit}
															onClick={() => onClickEdit(item)}
														/>
														<ActionIcon
															size="lg"
															className={'mx-2'}
															toolTipText='Eliminar Orden de provisiones'
															icon={faTrash}
															onClick={() => onClickDelete(item)}
														/>
													</td>
												</tr>);
										})
										:
										<tr>
											<td colSpan={colSpanCellsNumber} className='text-center'>
												{
													!isFetching &&
													<Alert variant='info' className='mb-0'>
														<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
														No hay Ordenes de provisiones o no se posee permiso de acceso a las mismas.
													</Alert>
												}
											</td>
										</tr>
								}
							</tbody>
						</Table>
					</Form>
					<RCPagination
						activePage={page}
						itemsCountPerPage={perPage}
						totalItemsCount={totalItems}
						totalItems={totalItems}
						thePage={page}
						onChange={onPageChange}
						innerClass="justify-content-center"
					/>
				</Card.Body>
			</Card>
			<AppLoading isLoading={isFetching || provisionOrderReportisFetching} />
		</Container>;
	</>
};

export default ProvisionOrderListPage;